.login-wrapper{
    position: fixed;
    left: 50%;
    top: 48%;
    margin-left: -125px;
    z-index: 10;
}
.room-id{
    width: 160px;
    height: 30px;
    padding: 5px;
    border: 1px solid #9fa7dc;
    border-radius: 4px;
    font-size: 14px;
    vertical-align: middle;
    background-color: #eee;
    
}
.join-in{
    width: 88px;
    height: 42px;
    border: 1px solid #9fa7dc;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    vertical-align: middle;
    background-color: #eee;
}
.active-btn{
    margin-top: 65px;
}
.active-btn button{
    width: 95px;
    height: 30px;
    background-color: #eee;
    font-size: 12px;
    border: 1px solid #9fa7dc;
    border-radius: 4px;
    cursor: pointer;
    vertical-align: middle;
}
.detecting{
    margin-left: 65px;
}
@media screen and (max-width: 720px) {
    .setting-modal.uc-fe-modal{
        width: 80% !important;
    }
    .setting-modal .uc-fe-form-label{
        width: 33% !important;
    }
    .setting-modal .uc-fe-form-controller{
        width: 66% !important;
    }
}